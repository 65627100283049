.text-with-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    align-items: center;
    align-content: center;

    gap: 8px;

    width: 100%;
    min-height: 60%;

    text-align: center;
}

.text-with-header--leftalign {
    text-align: left;
    align-items: start;
}



.text-with-header__header{
    font-family: 'Halvar Breitschrift';
    text-align: inherit;
    font-size: 25px;
    font-weight: 250;
    /* font-style: bold; */
    line-height: 25px;
    letter-spacing: 0px;
}


.text-with-header__caption{
    text-align: inherit;
    font-size: 17px;
    font-weight: 400;
}

.text-with-header__caption--justify{
    display: flex;
    justify-content: center; /* центрирует содержимое по горизонтали */
    text-align: justify; /* выравнивает текст по ширине внутри элемента */
    width: 100%; /* задает ширину контейнера */
}

.text-with-header__caption--justify > * {
    max-width: 90%;
}