


.progress-bar__container {
    position: relative;
    width: 100%;
    height: 46px;
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
}


.progress-bar__item{
    border-radius: 16px;
    position: absolute;
    height: 46px;
    width: 100%;
    transition: width 500ms ease;
}

.progress-bar__item--background{
    background-color: #E8ECFD;
}

.progress-bar__item--active{
    background-color: var(--app-color-purple);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    
    width: 100%;
}