

.promocode-screen{
    position: relative;

    height: 100%;
    width: 100%;
    box-sizing: border-box;

    background-color: var(--app-color-card-purple);
}

.promocode-panel{
    padding: 0;
}

.promocode-screen__background{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.preview-screen__background__logo{
    position: relative;
    width: 60%;
    max-width: 220px;
}

.preview-screen__background__logo > * {
    width: 100%;
    height: 100%;
}

.promocode-screen__background__biglogo{
    position: relative;
    width: 100%;
    /* box-sizing: content-box; */
}

.promocode-screen__background__biglogo > * {
    width: 100%;
    height: 100%;
}

.promocode-screen__bottom{
    position: absolute;
    bottom: 0;

    padding: 25px 10px;
}

.preview-screen__bottom-modal {
    padding: 10px 16px;
    color: white;
}

.bottom-modal__text{
    width: 100%;
    box-sizing: border-box;

    font-size: 17px;
    font-weight: 400;
    line-height: 20.4px;
}