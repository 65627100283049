
.panel {
    position: relative;
    width: 100vw;
    height: auto;
    padding: 8px 8px;

    box-sizing: border-box;

    animation: panel-appearence 500ms ease forwards;

    background-color: var(--app-background_color); 
}

@keyframes panel-appearence {
    from{
        opacity: 0;
        /* transform: translateX(-50%); */
    }to{
        opacity: 1;
        /* transform: translateX(0); */
    }
}


.panel--onescreen {
    height: var(--app-height--onescreen);
}

.panel--imagecover {
    background-size: contain;
    background-position-x: center;
    background-position-y: top;
    background-size: 100%;
    background-repeat: no-repeat;
}

.popout-cover{
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 0.245);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    
    overflow: hidden;

    animation: popout-appear 100ms ease-in;
}

@keyframes popout-appear {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
