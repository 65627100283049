

.icon-circle{
    all: unset;
    position: relative;
    width: 72px;
    height: 72px;

    background-color: var(--app-color-white);
    border-radius: 999px;

    border: 4px solid currentColor;

    cursor: pointer;
    transition: transform .05s ease-in-out, filter 0.05s ease-in-out;
    -webkit-tap-highlight-color: transparent;
}

.icon-circle:active{
    transform: scale(0.97);
    filter: contrast(0.985);
}

.icon-circle > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}


.icon-circle-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    align-content: center;

    gap: 20px;
}