.quiz-container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.quiz-container > * {
    width: 100%;
    box-sizing: border-box;
}


.quiz-container__image-part{
    flex: 1;

    transition: height 1s ease-in;
}

.quiz-container__action-part{
    
}

.quiz-container__action-part__in {
    background-color: var(--app-color-card-blue);
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 16px;
    padding-top: 0;
    gap: 10px;
}

.quiz-container__action-part--mobile {
    padding-top: 16px;
    border-radius: 20px;
}

.quiz-container__action-part__text{
    color: black;
    font-size: 17px;
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;
    word-break: normal;
    
    width: 100%;
    box-sizing: border-box;
}

.quiz-container__action-part__actions{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    justify-content: space-between;
}

.quiz-card-stack {
    position: relative;
    width: 100%;
    height: 100%;
}

.quiz-card-stack > * {
    position: absolute !important;
    width: 100%;
    height: 100%;
}

.quiz-card{
    width: 100%;
    height: 100%;

    border-radius: 20px;

    z-index: 0;

    /* transition: background .4s ease-in, background-color .4s ease-in; */

    background-size: cover;
    background-position: center center;

    overflow: hidden;

    background-color: #E3DFDC;
}

.quiz-card__cover{
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center center;

    animation: opacity-appearance .25s ease-in forwards;
}

.quiz-card--inmove {
    animation: inmove 1.1s ease-in-out;
}

@keyframes inmove {
    0%{
        transform: translateX(0) rotateZ(0);
    }
    50%{
        transform: translateX(100%) rotateZ(1deg);
    }
    100%{
        transform: translateX(0) rotateZ(0);
    }
}

@keyframes opacity-appearance {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}


.quiz-choice-button{
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    border-radius: 99px;
    overflow: hidden;
    position: relative;
    height: auto;
    width: 49%;
    box-sizing: border-box;
    background-color: #E3DFDC;

    transition: scale 150ms ease-in;
    /* flex: 1; */
}

.quiz-choice-button:active{
    scale: 0.95; /**/
}

.quiz-choice-button img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.between-choice-buttons{

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    padding: 8.25px 8.25px 9.75px 8.25px;
    border-radius: 8.02px;
    background-color: var(--app-color-purple);
    color: white;

    font-size: 16.04px;
    font-weight: 700;
    line-height: 16.04px;

}