

.platform-provider{
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    /* height: 100%; */

    transition: background-color .4s ease-in-out;
}

.platform-provider--desktop {
    position: relative;
    /* background-color: var(--app-background_color); */
    /* background-image: url('https://s3-alpha-sig.figma.com/img/0c42/b79e/830ada8d5c476f435406f145785d629e?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=THuhoY2-gJP6JkG0zJ0pfENTdiMsIqYMYJovBfU6jx34Ym3FzVXeZI0Erb2orMOFZFCNGnAygF27A0vRsUXNYWec6AVTnQXCKxum7CAocJrymHtXIQvUC~ZNXJvVk3DUEqykpOXh0vn-GbPcQC8pwGfbItMli-D0UwNszrKpd0wobJ5-e-G2C7bERzgHCv-5IGEAYm6wiao8wmMDHpe1sIgIygE56dDhmd12oiCaMGC3PFA66lov~aIDvazGvYyS2E4Ka341XrrQvVS29A~6OSqh1bG6acoXlTGBtOgC1gLxQxTtIINDk8We~cnQH4Dr6KM1uqU8hjtM4kEVdaKXEQ__'); */
    /* background-size: cover; */
    /* background-size: cover 100%; */
    padding: 10px 0;
    box-sizing: border-box;
}

.platform-provider--overflow{
    overflow: visible;
}

.platform-provider--smallpaddings .panel{
    padding: 10px 0 !important;
}

.platform-provider--ultrasmallpaddings .panel {
    padding: 0 !important;
}

.platform-provider--desktop .panel {
    border-radius: 13px;
    background-color: transparent !important;
    padding: 25px 0;
    /* box-shadow: 0 0 80px rgb(233, 233, 233); */
}

.platform-provider--desktop .panel--onescreen {
    height: calc(var(--app-height--onescreen) - 20px)
}

.platform-provider--desktop > *{
    position: relative;
    /* height: 757px; */
    width: max(600px, 40%);

    /* @media only screen and (max-width: 1440px) {
        width: max(800px, 40%);
    } */

    padding: 10px 15px;
}


.platform-provider--mobile > *{
    position: relative;
    /* height: 100%; */
    width: 100%;
}


/* QUIZ VIEW ADAPTIVE */

.platform-provider--desktop .quiz-container__action-part{
    position: absolute;
    bottom: 0;

    width: 65%;
    min-height: 25%;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
}

/* .platform-provider--desktop .quiz-container__action-part__actions {
    justify-content: center;
    gap: 12px;
} */

/* .platform-provider--desktop .quiz-choice-button {
    max-height: 170px;
    max-width: 170px;
} */

.platform-provider--desktop .quiz-container__action-part::before {
    content: '';
    position: absolute;
    bottom: -16px;
    width: 100px;
    height: 16px;
}

.platform-provider--desktop .quiz-container__image-part{
    height: 60dvh;
    max-height: 60%;
}

.platform-provider--desktop .quiz-container{
    justify-content: start;
}

.platform-provider--desktop .quiz-container__action-part__text{
    text-align: center;
}

.platform-provider--desktop .alert-popout {
    min-width: 300px;
    width: 17%;
}

.platform-provider--desktop .quiz-progress {
    margin-bottom: 8px;
}


.platform-provider--desktop .quiz-card {
    border-radius: 32px;
}



/* SELECT CITY VIEW ADAPTIVE */

.platform-provider--desktop .selecting-place {
    gap: 24px;
}

.platform-provider--desktop .selecting-place__button {
    width: 62%;
    align-self: center;
}

/* .platform-provider--desktop .selecting-place__selector{
    margin-bottom: 16px;
    clip-path: none !important;
} */

.platform-provider--desktop .scroll-select__items{
    padding: 25% 0;
}


/* PROMOCODE VIEW ADAPTIVE */

.platform-provider--desktop .promocode-screen {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    /* align-content: center; */
}

.platform-provider--desktop .promocode-screen__bottom{
    width: 60%;
    position: relative;
}

.platform-provider--desktop .promocode-screen__background__biglogo {
    height: 100%;
    background-color: #E8DDFF;
    width: 600px;
    height: 360px;
    clip-path: url("#clip-block_great_dir_desktop") !important;
}

.platform-provider--desktop .promocode-screen__background__biglogo > * {
    scale: 1.5;
    padding-top:5%;
}

.platform-provider--desktop .promocode-screen__background {
    height: 60%;
    flex: 1;
}

/* RESULTS VIEW ADAPTIVE */

.platform-provider--desktop .result-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.platform-provider--desktop .result-panel > * {
    width: 70%;
}

.platform-provider--desktop .result-banner {
    width: 100%;
}

.platform-provider--desktop .result-banner__main-part {
    clip-path: none !important;
    border-radius: 30px;
    margin-bottom: 16px;
}

.platform-provider--desktop .result-banner__bottom-part__out {
    width: 70%;
}

.platform-provider--desktop .result-banner__main-part__bottom {
    width: 90%;
    align-self: center;
}

.platform-provider--desktop .sticker--big-right {
    max-height: 170px;
    max-width: 170px;

    @media only screen and (max-width: 1440px) {
        max-height: 160px;
        max-width: 160px;
    }
}

.platform-provider--desktop .result-banner__main-part {
    overflow: visible;
}

.platform-provider--desktop .result-banner__main-part__in {
    padding-bottom: 16px;
    padding-top: 0;

    margin-top: min(12%, 100px);
}

.platform-provider--desktop .result-banner__main-part__title {
    font-size: 17px;

    @media only screen and (max-width: 1440px) {
        font-size: 16px;
    }
}

.platform-provider--desktop .result-banner > * {
    margin: -7px 0;
}


/* PREQUIZ VIEW ADAPTIVE */

.platform-provider--desktop--pink {
    background-color: #E8DDFF;
}

.platform-provider--desktop--pink-pattern::after {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%; 
    height: 100%;  
    opacity: .3; 
    z-index: -1;
    background-color: #E8DDFF;
}

.platform-provider--desktop .preview-screen--rent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.platform-provider--desktop .preview-screen__bottom--rent {
    position: relative;
    display: flex;
    width: 100%;
}

.platform-provider--desktop .preview-screen__background--rent {
    justify-content: center;
    align-items: center;
}

/* .platform-provider--desktop .preview-screen__background--rent{
    height: 50%;
    background-color: #E8DDFF;
    border-radius: 30px;
} */

/* .platform-provider--desktop .preview-screen__background__biglogo{
    height: 100%;
} */

/* .platform-provider--desktop .preview-screen__background--rent{
    transform: translateY(4%);
    top: 25%;
} */


.platform-provider--desktop .preview-screen{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.platform-provider--desktop .preview-screen__bottom {
    width: 75%;
}

.platform-provider--desktop .prequiz-screen::before {
    all: unset;
}

.platform-provider--desktop .preview-screen--rent::before {
    all: unset;
}

.platform-provider--desktop .preview-screen__background__biglogo--rent > * {
    scale: 1.2;

    @media (min-width: 1920px) {
        scale: 1.5;
    }

    @media (min-width: 2880px) {
        scale: 2.3;
    }
}