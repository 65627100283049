

.preview-screen{
    position: relative;

    height: 100%;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    overflow-x: visible;

    /* padding-top: 30px; */
}

.prequiz-screen{
    background-color: var(--app-color-card-purple);
}

.prequiz-screen::before {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;

    width: 100%; 
    height: 100%;  
    opacity: .7; 
    z-index: -1;
    background-image: url('https://sun9-45.userapi.com/impg/7EuKPPBgpd5vtVd839w_FH_osHpMeE459AkHRA/BVgJiG8Z22w.jpg?size=2160x1440&quality=95&sign=1378aa7ad6decdeedab895b41c030ab4&type=album');
    background-size: cover;
    background-position: center center;
}

.preview-screen__background{
    /* padding: 10px 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    /* gap: 44px; */
    box-sizing: border-box;
    /* max-width: 500px; */
    justify-content: space-between;
    height: 100%;
    width: 100%;

    flex: 1;
}

.preview-screen__background__logo{
    padding: 20px;
    position: relative;
    width: 60%;
    max-width: 220px;
}

.preview-screen__background__logo > * {
    width: 100%;
    height: 100%;
}

.preview-screen__background__biglogo{
    position: relative;
    width: 110%;
    height: 100%;
    box-sizing: content-box;
}

.preview-screen__background__biglogo > * {
    object-fit: contain;
    object-position: center center;
    /* width: 100%;
    height: 100%; */
}

.preview-screen__bottom{
    position: relative;
    /* width: 100%; */
    /* position: absolute;
    bottom: 0; */
}

.preview-screen__bottom-modal {
    height: 100%;
    padding: 10px 16px;
    color: white;
}

.bottom-modal__text{
    width: 100%;
    box-sizing: border-box;

    font-size: 17px;
    font-weight: 400;
    line-height: 20.4px;
}