

.preresult-place{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    /* gap: -10px; */

    padding: 5px 0;

    box-sizing: border-box;
}

.preresult-place > * {
    margin: -3px 0;
}

.preresult-place__selector{
    position: relative;
    transition: background .4s ease-in-out !important;
    flex: 12;
    border-radius: 16px;
    background-color: var(--app-color-card-purple);

    overflow: hidden;
}


.center-sticker{
    position: absolute;
    width: 150px;
    height: 150px;
    background-color: #E3DFDD;
    border-radius: 999px;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.center-sticker img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}


.preresult-place__selector__innercontent{
    position: absolute;
    width: 100%;
    height: 100%;

    object-fit: cover;


    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    
    transform: translateY(-50%) ;
}

.preresult-place__selector__innercontent path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 4s ease-in-out forwards;
}

  
@keyframes dash {
    100% {
        stroke-dashoffset: 0;
    }
}


.lines__top-line{
    position: absolute;
    transform: scale(1);
    top: 0;
}


.preresult-place__text{
    flex: 1.3;
    border-radius: 18px;
    background-color: var(--app-color-purple);

    transition: background-color .3s ease-out !important;

    color: white;
    font-size: 17px;
    font-weight: 400;
    line-height: 20.4px;
    text-align: center;
    text-decoration-skip-ink: none;


    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 min(5%, 13px);
    padding-top: min(10%, 26px);
    padding-bottom: min(5%, 13px);
    /* padding-top: 3%; */
}


