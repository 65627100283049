.stretched-box{
    display: flex;
    flex-direction: column;
    justify-content: end;

    gap: 0;

    width: 100%;
    height: 100%;

    box-sizing: border-box;
}