

.bottom-gradient{
    position: fixed;
    /* width: 100%; */
    box-sizing: content-box;
    /* background-color: red; */
    bottom: 0;
    left: 0;
    right: 0;
}