.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #898989; /* Цвет фона спиннера */
    border-top: 4px solid #fff; /* Цвет верхней части спиннера */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Анимация вращения */
}

.spinner-case{
    padding: 18px;
    border-radius: 13px;
    background-color: white;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}