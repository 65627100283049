.parted-progress{
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 4px;
}


.parted-progress__item{
    flex: 1;
    width: auto;
    height: 4px;
    border-radius: 2px;

    background-color: var(--app-color-purple);
    opacity: .3;

    transition: opacity 400ms ease;
}


.parted-progress__item--active{
    background-color: var(--app-color-purple);
    opacity: 1;
}


