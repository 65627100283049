


.text-block{
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 20.4px;

    max-width: 90%;
    margin: 0 auto;

    padding-top: 12px;
    padding-bottom: 33px;
}


.result-card{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    width: 100%;
    min-height: 125px;
    height: 31dvh;
    max-height: 150px;
    
    box-sizing: border-box;
    padding: 20px;
    padding-left: 16px;
    padding-top: 18px;

    background-color: var( --app-color-card-yellow);
    border-radius: 26px;
}

.result-card__values-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    flex: 1;

    height: 100%;
}

.result-card__values-column span {
    word-break: normal;
}

.result-card__values-column .result-card__values__top {
    font-size: 32px;
    font-weight: 500;
    
    z-index: 1;
}



.result-card__gap{
    width: 100%;
    /* height: 50px; */
    /* height: 15dvh; */
    height: max-content;
}

.result-card__sticker{
    /* position: absolute; */

    aspect-ratio: 1;

    /* width: 60%; */
    box-sizing: border-box;
    height: 100%;
    width: auto;

    z-index: 0;
    
    overflow: visible;
    /* transform: rotateY(180deg) translateY(-50%); */
}

.result-card__sticker img {
    border-radius: 20px;
    border: 4px solid #fff;
    object-fit: cover;
    width: 100%;
    height: auto;
}


.cards-handler{
    display: flex;
    flex-direction: column;

    padding: 24px 8px 24px 8px;
    gap: 20px;
    border-radius: 16px;
    background-color: var(--app-color-card-secondary);

    box-sizing: border-box;
}

.advice-card {
    display: flex;
    flex-direction: column;

    padding: 24px;
    gap: 8px;

    border-radius: 26px;
    background-color: var(--app-color-card-purple);
}

.advice-card__top {
    color: var(--app-color-purple);
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
}

.advice-card__caption {
    font-size: 17px;
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;
}


.result-banner{
    position: relative;
    display: flex;
    flex-direction: column;

    align-items: center;
    align-content: center;


    height: auto;
    /* min-height: 644px; */
    /* max-height: 1000px; */
    box-sizing: border-box;
    /* padding-bottom: 40px; */

    /* overflow: visible; */
}

.result-banner__main-part{
    background-color: var(--app-color-card-purple);
    flex: 4.5;

    width: 100%;
    box-sizing: border-box;

    padding: 16px;
    padding-bottom: 32px;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.result-banner__main-part__out{
    position: relative;
    flex: 4.5;
}

.result-banner__main-part__in{
    background-color: var(--app-color-card-purple);

    width: 100%;
    box-sizing: border-box;

    padding: 16px;
    padding-bottom: 0;
    /* padding-bottom: 32px; */
    /* margin-bottom: -32px; */

    position: relative;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;

    margin-top: min(15%, 100px);
}


.result-banner__main-part__top{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;

    align-items: baseline;
}

.result-banner__main-part__bottom{
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 10px;

    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.result-banner > * {
    margin: -7.6px 0;

}


.result-banner__bottom-part__in{
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    align-content: center;

    padding: 10px 20px;
    /* padding-top: 10px; */
    /* paddi */
    /* flex: 1; */
    padding-bottom: min(30%, 60px);

    width: 100%;
    box-sizing: border-box;

    background-color: var(--app-color-card-purple);

    font-size: 17px;
    font-weight: 400;
    line-height: 20.4px;
    text-align: center;

    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


.result-banner__bottom-part__socials{
    position: absolute;

    bottom: 0;
    transform: translateY(50%);
}


.result-banner__main-part__logo{
    position: relative;
    /* padding: 16px; */
    width: 50%;
    max-width: 200px;
    margin-top: 10px;
}

.result-banner__main-part__logo > * {
    width: 100%;
    height: 100%;
}

.result-banner__main-part__title{
    font-size: 17px;
    font-weight: 400;
    line-height: 100%;
    /* break-before: always; */
    width: calc(100% - 24px);
    box-sizing: border-box;

    text-align: center;
    /* margin-top: -10px; */

    z-index: 1;
}

.result-banner__main-part__title__action{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    margin-bottom: 14px;
    margin-top: 3px;
    /* padding: 12px; */
    /* background-color: rgba(255, 255, 255, 0.22); */

    color: var(--app-color-purple);
    width: calc(100% - 24px);
}

.result-action-box{
    padding: 10px 8px;
    background-color: rgba(255, 255, 255, 0.29);
    border-radius: 14px;
}

.result-button--small{
    padding: 11px 14px 11px 14px;
    border-radius: 13px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;

    z-index: 10;
}


.result-banner__stickers-cover{
    position: absolute;
    top: 0;
    bottom: 5%;
    left: 0;
    right: 0;
}

.result-banner__stickers-container{
    position: relative;
    width: 100%;
    height: 100%;
}

.result-banner__stickers-container__sticker{
    /* position: absolute */
}

.sticker--big-right{
    /* position: absolute; */
    max-height: 210px;
    max-width: 210px;
    width: 35%;
    aspect-ratio: 1;
    top: 0;
    /* left: 50%; */
    transform: translateY(-35%);

    margin-bottom: -6%;
    
    @media only screen and (max-width: 300px) {
        margin-bottom: -7%;
    }

    @media only screen and (min-width: 301px) and (max-width: 340px) {
        margin-bottom: -10%;
    }

    @media only screen and (min-width: 341px) and (max-width: 400px) {
        margin-bottom: -11%;
    }

    @media only screen and (min-width: 401px) and (max-width: 500px) {
        margin-bottom: -10%;
    }
    /* right: 30%; */
    /* top: 50%; */
    /* transform: translateY(-60%) translateX(70%); */

}

.sticker--big-right img {
    /* transform: rotateZ(3.5deg); */
    border-radius: 25px;

    border: 4px solid white;
    /* box-shadow: ; */
}

.result-banner__stickers-container__sticker img {
    object-fit: cover;
    /* width: 100%; */
    height: 100%;
    width: 100%;
}

.sticker--small-left {
    /* flex-shrink: 1; */
    flex: 1;
    width: 40%;
    max-height: 100px;
    max-width: 190px;
    /* width: 20%; */
    transform: rotateZ(-144deg);
}

.sticker--small-left img {
    object-fit: cover;
    height: auto;
    width: 100%;
}


.down-fixed {
    position: fixed;
    bottom: 0;

    left: 0;
    right: 0;
    padding: 10px;
    box-sizing: border-box;
    height: auto;

    margin: 0 auto;

    z-index: 99;
}

.arenda-button{
    width: 50%;
    border-radius: 8px;
}


.block-select-city__in{
    background-color: var(--app-color-card-yellow);
    padding: 16px;
    padding-top: 0;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.block-select-city__text {
    text-align: center;
}

.block-select-city__out{
    display: block;
}


.text-block--purple{
    padding: 24px;
    gap: 8px;
    border-radius: 16px;
    background-color: var(--app-color-card-purple);
    box-sizing: border-box;

    max-width: 100%;

}