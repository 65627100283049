

.screen-parts{
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    gap: 16px;
}

.screen-parts__top{
    position: relative;
    flex: 1.5;

    background: var(--app-color-card-blue);
}

.screen-parts__bottom{
    position: relative;
    flex: 2;

    background: var(--app-color-card-yellow);
    overflow: visible;
}

.preview-logo{
    position: absolute;
    top: calc(42% + 43px);
    left: 30%;
    transform: translateY(-50%) translateX(-50%);
}


.topline{
    position: relative;
    height: 100%;
    width: max(100%, 340px);
    overflow: hidden;
}

.platform-provider--desktop .topline > * {
    bottom: 0;
}

.topline > * {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;

    bottom: -5%;
}


.bottomline{
    position: relative;
    height: 100%;
    width: max(100%, 340px);
    overflow: hidden;
}


.bottomline > * {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;

    top: 0;
}


.line-b{
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: loadling-line-b-0 2s ease forwards;
    animation-delay: 750ms;
}

.line-b-4, .line-b-5{
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: loadling-line-b-0 2s ease forwards;
    animation-delay: 1.13s;
}

@keyframes loadling-line-b-0 {
    100% {
        stroke-dashoffset: 0;
    }
}

.line-t{
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: loadling-line-t-0 2s ease forwards;
}

@keyframes loadling-line-t-0 {
    100% {
        stroke-dashoffset: 0;
    }
}

.stickers {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.stickers > * {
    position: absolute;
}

.loading-sticker{
    padding: 10.69px 21.39px 18px 21.39px;
    border-radius: 21.39px;

    font-size: 42.78px;
    font-weight: 700;
    line-height: 42.78px;
    text-align: center;

    background-color: var(--app-color-white);
}

.tip-b-1, .tip-b-2, .tip-b-3, .tip-b-4, .tip-b-5, .tip-b-6 {
    /* display: none; */
    /* animation: tip-rotate 1500ms ease infinite; */
}

.tip-b-1{
    transform: translateY(-20vh);
    transform-origin: 62% 22%;
    animation: move-b-1 1.5s ease forwards;
    animation-delay: 750ms;
}

@keyframes move-b-1 {
    from{
        transform: translate(60%, -20vh) rotate(-15deg);
    }
    to{
        transform: translate(0, 0) rotate(0);
    }
}

.tip-b-2{
    transform: translateY(-60vh);
    transform-origin: 55% 75%;
    animation: move-b-2 1.6s ease forwards;
    animation-delay: 550ms;
}

@keyframes move-b-2 {
    from{
        transform: translateY(-60vh) translateX(10%) rotate(25deg);
    }
    to{
        transform: translateY(0) translateX(0) rotate(0);
    }
}

.tip-b-3{
    transform: translateY(-70vh);
    transform-origin: 25% 10%;
    animation: move-b-3 1.5s cubic-bezier(0.98, 0.06, 0, 1.01)  forwards;
    animation-delay: 350ms;
}

@keyframes move-b-3 {
    0%{
        transform: translate(100%, -60vh);
    }
    100%{
        transform: translate(0, 0) rotate(0);
    }
}

.tip-b-4{
    transform: translateY(-80vh);
    transform-origin: 17% 50%;
    animation: move-b-4 1.6s ease forwards;
    animation-delay: 550ms;
}

@keyframes move-b-4 {
    from{
        transform: translate(50%, -70vh) rotate(-100deg);
    }
    to{
        transform: translate(0, 0) rotate(0);
    }
}

.tip-b-5{
    transform: translateY(-80vh);
    transform-origin: 65% 45%;
    animation: move-b-5 1.6s ease forwards;
    animation-delay: 550ms;
}

@keyframes move-b-5 {
    from{
        transform: translate(0%, -80vh);
    }
    to{
        transform: translate(0%, 0);
    }
}

.tip-b-6{
    transform: translateY(-40vh);
    transform-origin: 25% 30%;
    animation: move-b-6 1.6s ease forwards;
    animation-delay: 550ms;
}

@keyframes move-b-6 {
    from{
        transform: translateY(-40vh) translateX(90%) rotate(100deg);
    }
    to{
        transform: translateY(0) translateX(0) rotate(0);
    }
}

@keyframes tip-rotate {
    from{
        transform: rotate(0);
    }
    to{
        transform: rotate(360deg);
    }
}


.platform-provider--desktop .lines-b {
    transform: translateX(30px);
}