

.custom-block-template{
    display: flex;
    flex-direction: column;
    gap: 0;

    justify-content: start;

    align-items: center;
    align-content: center;

    box-sizing: border-box;
}


.custom-block-template__additional{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: end;
    margin: -1px 0;
}

.custom-block-template__additional > * {
    width: 100%;
    height: 100%;
}

.custom-block-template__body{
    /* border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px; */

    flex: 1;

    width: 100%;
    box-sizing: border-box;

    background-color: inherit;
    background: inherit;
}

.custom-block-template__body--start {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
}

.custom-block-template__body--end {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
}