.scroll-select{
    position: relative;
    width: 100%;
    height: 100%;

    box-sizing: border-box;

    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll-select__items{
    display: flex;
    flex-direction: column;

    align-items: center;
    align-content: center;

    text-wrap: nowrap;
    box-sizing: border-box;

    gap: 8px;

    position: absolute;

    left: 50%;
    transform: translateX(-50%);

    padding: 50% 0;
    /* top: 150%; */
    /* bottom: 50%; */
}


.scroll-select__item{
    display: inline;

    font-weight: 700;
    font-size: 21.39px;
    padding: 7px 12px 10px 12px;
    text-align: center;

    border-radius: 10.69px;

    background: rgba(255, 255, 255, 0.6);
    color: #000;

    transition: background 190ms ease-in-out, padding 180ms ease, letter-spacing 630ms ease;
}

.scroll-select__item--selected{
    font-size: 42.78px;

    letter-spacing: -1px;

    border-radius: 21.39px;
    padding: 14px 24px 20px 24px;

    background: #000;
    color: #fff;

    /* max-width: 80%; */
    box-sizing: border-box;

    @media (max-width: 500px) {
        font-size: 35.78px;
    }

    @media (max-width: 450px) {
        font-size: 29.78px;
    }

    @media (max-width: 370px) {
        font-size: 26.78px;
    }

    @media (max-width: 357px) {
        font-size: 25.78px;
    }

    @media (max-width: 335px) {
        font-size: 23.78px;
    }

    @media (max-width: 313px) {
        font-size: 21.78px;
    }

}
