

.selecting-place{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    /* gap: -10px; */

    padding: 5px 0;

    box-sizing: border-box;
}

.selecting-place > * {
    margin: -3px 0;
}

.selecting-place__selector{
    flex: 1;
}

.selecting-place__selector__in{
    /* border-radius: 16px; */
    background-color: #E8DDFF !important;
    /* background: radial-gradient(110.53% 91.76% at 101.62% 0.76%, rgb(125, 36, 255) 0%, rgb(0, 0, 0) 100%) !important; */

    overflow-y: scroll;
}



.selecting-place__button__in{
    background-color: #E8DDFF !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 16px;
    padding-top: 0;
    /* padding: 0 min(5%, 13px);
    padding-top: min(10%, 26px);
    padding-bottom: min(5%, 13px); */
    /* padding-top: 3%; */
}

.selecting-place__selector__in--mobile{
    position: relative;
}

.select-area__gradient{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70px;
    z-index: 999;
    transform: translateY(25%);

    background: linear-gradient(0deg, rgba(232,221,255,1) 0%, rgba(232,221,255,1) 40%, transparent 110%);
    /* background: #E8DDFF; */
}