.preview-screen--rent{
    position: relative;

    height: 100%;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
}

.preview-screen__background--rent{
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    transform: translateY(13%);
    /* justify-content: center !important; */
    gap: 44px;
}

.preview-screen__background__biglogo--rent{
    position: relative;
    width: 100%;
    max-width: 450px;
    box-sizing: content-box;
}

.preview-screen__background__biglogo--rent > * {
    width: 100%;
    height: 100%;
    scale: 1.3;
    /* transform: translateX(6%); */
}

.preview-screen__bottom--rent{
    margin: 0 8px;
    /* position: absolute !important; */
    /* bottom: 0; */
    /* width: calc(100% - 20px); */
    /* box-sizing: border-box; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    
}


.preview-screen--rent::before {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;

    width: 100%; 
    height: 100%;  
    opacity: .7; 
    z-index: -1;
    background-image: url('https://sun9-45.userapi.com/impg/7EuKPPBgpd5vtVd839w_FH_osHpMeE459AkHRA/BVgJiG8Z22w.jpg?size=2160x1440&quality=95&sign=1378aa7ad6decdeedab895b41c030ab4&type=album');
    background-size: cover;
    background-position: center center;
}