.button{
    all: unset;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    display: block;

    border: none;
    outline: none;

    padding: 16px;
    border-radius: 13px;

    background-color: var(--app-color-white);

    text-align: center;

    transition: transform 0.05s ease-in-out, filter 0.05s ease-in-out;
}

.button--slim{
    padding: 12px;
}

.button--innervalue{
    font-weight: 500;
    font-size: 17px;
}

.button--white-color{
    background-color: var(--app-color-white);
    color: #000;
}

.button--black-color{
    background-color: #000;
    color: var(--app-color-white);
}

.button--white-light-color{
    background-color: #FFFFFF1F;
    color: #fff;
    border: 1px solid #FFFFFF3D;
}

.button--purple-color{
    background-color: var(--app-color-purple);
    color: #fff;
}

.button--purple-light-color{
    background-color: #7D24FF1F;
    color: var(--app-color-purple);
    border: 1px solid #7D24FF3D;
}

.button--stretched {
    width: 100%;
    box-sizing: border-box;
}

.button:active {
    transform: scale(0.97);
    filter: contrast(0.99);
}