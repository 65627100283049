@font-face {
  font-family: 'Yandex Sans Text';
  src: url('./fonts/YS\ Text-Regular.ttf') format('truetype'),
}

@font-face {
  font-family: 'Halvar Breitschrift';
  src: url('./fonts/HALVARBREIT-BD.TTF') format('truetype'),
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: 'Yandex Sans Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;

  height: 100dvh; /* Высота 100% от высоты видимой области */
  /* background-image: url('https://sun9-45.userapi.com/impg/7EuKPPBgpd5vtVd839w_FH_osHpMeE459AkHRA/BVgJiG8Z22w.jpg?size=2160x1440&quality=95&sign=1378aa7ad6decdeedab895b41c030ab4&type=album'); */
  background-size: cover; /* Изображение будет растянуто, чтобы покрыть весь элемент */
  background-position: center top; /* Центрируем изображение */
  background-repeat: no-repeat; /* Не повторяем изображение */
}

body[bg-pattern='true'] {
  background-image: url('https://sun9-45.userapi.com/impg/7EuKPPBgpd5vtVd839w_FH_osHpMeE459AkHRA/BVgJiG8Z22w.jpg?size=2160x1440&quality=95&sign=1378aa7ad6decdeedab895b41c030ab4&type=album');
}

.bold-font {
  font-family: 'Halvar Breitschrift';
}

.clippy-base{
  position: absolute;
  top: -999px;
  left: -999px;
  width: 0;
  height: 0;
}

:root {
  --app-height--onescreen: 100dvh;

  --app-background_color: #fff;

  --app-color-gray: #E3DFDC;
  --app-color-purple: #7D24FF;
  --app-color-purple--selecction: #ca9ffe;
  --app-color-white: white;

  --app-color-card-purple: #E8DDFF;
  --app-color-card-yellow: #FFE671;
  --app-color-card-blue: #E8ECFD;
  --app-color-card-secondary: #F4F5FB;
}
