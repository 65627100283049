

.bigicon-star{
    transform-origin: center center;
    animation: rotate 3s ease-in-out infinite;
}

@keyframes rotate {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(0.97) rotate(5deg);
    }
    100%{
        transform: scale(1);
    }
}